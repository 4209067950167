<template>
  <div>
    <div class="card card-info">
      <div class="card-header pt-2 pb-2">
        <a
          data-toggle="collapse"
          href="#firmas"
          aria-expanded="true"
          id="heading-firmas"
          class="d-block"
        >
          <i class="fa fa-chevron-down pull-right"></i>
          Firmas Contratos
        </a>
      </div>
      <div id="firmas" class="collapse">
        <div class="card-body">
          <div class="row" v-for="firma in listasForms.firmas" :key="firma.id">
            <div class="form-group">
              <div
                class="custom-control custom-switch custom-switch-off-secundary custom-switch-on-success"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'firma_' + firma.id"
                  @change="actualizarFirma(firma.id)"
                  v-model="form.firma[firma.id]"
                  :checked="firma.estado"
                />
                <label
                  class="custom-control-label"
                  :for="'firma_' + firma.id"
                  >{{ firma.descripcion }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UsuarioFirma",
  data() {
    return {
      form: {
        firma: [],
      },
      listasForms: {
        firmas: [],
      },
      cargando: false,
    };
  },
  methods: {
    getFirmasUser() {
      axios
        .get("/api/admin/usuarios/firmas?usuario=" + this.$parent.form.id)
        .then((response) => {
          this.listasForms.firmas = response.data;
          for (let i = 0; i < this.listasForms.firmas.length; i++) {
            const firma = this.listasForms.firmas[i];
            this.form.firma[firma.id] = firma.estado;
          }
        });
    },

    actualizarFirma(firma) {
      axios
        .put("/api/admin/usuarios/assingOrRevokeFirma", {
          user: this.$parent.form.id,
          firma: firma,
          accion: this.form.firma[firma],
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.getFirmasUser();
  },
};
</script>
